const goodsTypeList = [
  { name: "电子产品", id: 1, select: true },
  { name: "书籍", id: 2, select: false },
  { name: "服饰", id: 3, select: false },
  { name: "家具", id: 4, select: false },
  { name: "美妆", id: 5, select: false },
  { name: "玩具", id: 6, select: false },
  { name: "其他", id: 7, select: false },
];
export { goodsTypeList };
