<template>
  <div class="page">
    <div class="searchBox">
      <div class="search-l">
        <input v-model="goodsName" type="text" placeholder="请输入好物名称" />
      </div>
      <div class="search-r" @click="search()">搜索</div>
    </div>
    <div class="optBox">
      <img
        src="./img/fbhw.png"
        alt=""
        @click="toPath('publishGoodThingsList')"
      />
      <img src="./img/xzqg.png" alt="" />
      <div class="box1" @click="toPath('ldlePurchaseList')"></div>
      <div class="box2" @click="toPath('myCollectionList')"></div>
    </div>
    <!-- <div class="myLeaveMessage" @click="toPath('goodsLeaveMessage')">
      我的留言
    </div> -->
    <div class="tabList">
      <div
        @click="tabChoose(t)"
        :class="['tab-item', { 'tab-item-act': t.select }]"
        v-for="(t, i) in tabList"
        :key="i"
      >
        {{ t.name }}
      </div>
    </div>
    <div>
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="list">
          <div
            class="item"
            @click="toPath('goodsDetail', true, t.goodsId)"
            v-for="(t, i) in listData"
            :key="i"
          >
            <div class="img">
              <img :src="t.goodsPhoto.split(',')[0]" :alt="t.goodsName" />
            </div>
            <div class="name">
              {{ t.goodsName }}
            </div>
            <div class="name">
              {{ t.goodsContent }}
            </div>
            <div class="info1">
              <div class="price"><span>¥</span>{{ t.goodsPrice }}</div>
              <div class="likeNum">{{ t.collectNumber }}人收藏</div>
            </div>
            <div class="info2">
              <div class="headimg">
                <img
                  :src="t.avatar || require('@/assets/img/default_avatar.png')"
                  alt=""
                />
              </div>
              <div class="username">{{ t.nickName }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { goodsTypeList } from "./map";
import { goodsListUrl } from "./api";
import { handleImg } from "@/utils/utils.js";
import { toRegister, gloabalCount } from "@/utils/common.js";

export default {
  name: "ldleTransaction",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      tabList: [
        { name: "全部", id: 0, select: true },
        { name: "电子产品", id: 1, select: false },
        { name: "书籍", id: 2, select: false },
        { name: "服饰", id: 3, select: false },
        { name: "家具", id: 4, select: false },
        { name: "美妆", id: 5, select: false },
        { name: "玩具", id: 6, select: false },
        { name: "其他", id: 7, select: false },
      ],
      goodsName: "",
      goodsType: 0,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    gloabalCount("", 90, 1);
  },
  methods: {
    search() {
      this.goodsType = 0;
      this.requestData.curPage = 1;
      this.finished = false;
      this.listData = [];
      this.onLoad();
    },
    tabChoose(t) {
      this.tabList.forEach((item) => {
        item.select = false;
      });
      t.select = true;
      this.goodsType = t.id;
      this.requestData.curPage = 1;
      this.finished = false;
      this.listData = [];
      this.onLoad();
    },
    async toPath(name, flag, id) {
      if (await toRegister(this.$route.path, this.$route.query, "闲置好物")) {
        if (flag) {
          this.$router.push({
            name: name,
            query: {
              goodsId: id,
            },
          });
        } else {
          this.$router.push({
            name: name,
          });
        }
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        goodsType: this.goodsType,
        tenantId: this.tenantId,
        goodsName: this.goodsName,
      };
      this.$axios
        .get(`${goodsListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.goodsPhoto) {
                item.goodsPhoto = handleImg(332, 332, item.goodsPhoto);
              }
              this.listData.push(item);
            });
          } else {
            this.finished = true;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 12px 30px 60px 30px;
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 332px;
      margin-bottom: 30px;
      box-sizing: border-box;
      .info2 {
        display: flex;
        align-items: center;
        .username {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        .headimg {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #e02020;
          overflow: hidden;
          margin-right: 14px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .info1 {
        display: flex;
        align-items: center;
        .likeNum {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          padding-left: 20px;
        }
        .price {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e02020;
          span {
            font-size: 20px;
          }
        }
      }
      .name {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        word-break: break-all;
        line-height: 40px;
        margin-bottom: 14px;
      }
      .img2 {
        width: 100%;
        height: 426px;
        background-color: #fee60e;
        border-radius: 14px;
        overflow: hidden;
        margin-bottom: 14px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .img {
        width: 100%;
        height: 332px;
        border-radius: 14px;
        overflow: hidden;
        margin-bottom: 14px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tabList::-webkit-scrollbar {
    display: none;
  }
  .tabList {
    height: 52px;
    display: flex;
    line-height: 52px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin-bottom: 36px;
    .tab-item-act {
      font-weight: 600;
      background: #fee60e !important;
    }
    .tab-item {
      height: 100%;
      min-width: 154px;
      background: #f7f7f7;
      border-radius: 26px;
      text-align: center;
      margin-right: 20px;
    }
  }
  .myLeaveMessage {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    height: 88px;
    background: #f7f7f7;
    border-radius: 16px;
    line-height: 88px;
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .optBox {
    height: 416px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .box2 {
      position: absolute;
      width: 352px;
      bottom: 0;
      right: 0;
      height: 170px;
    }
    .box1 {
      position: absolute;
      width: 352px;
      top: 0;
      right: 0;
      height: 246px;
    }
    img {
      width: 352px;
      height: 100%;
    }
  }
  .searchBox {
    width: 100%;
    height: 74px;
    background: #ffffff;
    border-radius: 37px;
    border: 4px solid #fee60f;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 20px;
    .search-r {
      width: 150px;
      height: 100%;
      background: #fee60f;
      border: 2px solid #fee60f;
      text-align: center;
      border-radius: 37px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 66px;
    }
    .search-l {
      flex: 1;
      height: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 66px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>
